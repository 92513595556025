@import "../../styleConstants.scss";



.simpleTabs__Container{
    flex:1;
    margin-top:15px;
    display: flex;
    flex-direction: column;
}

.simpleTabs> div>a{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid $clearGray;
    border-left:  1px solid transparent;
    text-align: center;
    color: $darkBlue;
    background-color: $tableEven;
    transition: 0.3s all ease-in-out;
}

.simpleTabs>div>a.active{
    border-top: 1px solid $clearGray;
    border-right: 1px solid $clearGray;
    border-bottom: 1px solid transparent;
    border-left:  1px solid $clearGray;
    background-color: transparent;
}

.simpleTabs>div>a:hover{
    border-top: 1px solid $clearGray;
    border-right: 1px solid $clearGray;
    border-left:  1px solid $clearGray;
    background-color: $tableHover;
}

.simpleTabs__filler{
    border-bottom: 1px solid $clearGray;
}

.simpleTabs__Subtitle{
    color: $darkBlue;
    font-weight: Bold;
    font-size:  20px;
    line-height: 26px;
}

.simpleTabs__thirdTitle{
    position: relative;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    vertical-align: bottom;
    text-align: left;
    color: $darkBlue;
    padding-left: 13px;
}
.simpleTabs__thirdTitle::before{
    position: absolute;
    content: "";
    border-radius: 10px;
    width: 5px;
    height: 5px;
    left: 0px;
    top: 7px;
    background: #005DBF;
    margin: 0px 4px;
}

.simpleTabs__Area{
    padding: 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.simpleTabs__relative{
    position: relative;
    overflow-y: scroll;
    //height: 100%;
    min-height: 300px;
    flex: 1;
}

.simpleTabs_scroll{    
    flex-grow: 1;
    overflow: auto;
    /* for Firefox */
    min-height: 0;
}

.simpleTabs__wrap{
    width: 100%;
}

@media (min-width:632){
    .simpleTabs__wrap{
        min-width: 470px;
        width:"35vw";
    }
}
